import React from "react";
import "../components/style.scss";
const ThanksPage = () => (
  <div>
    
    <section className="hero primary first is-fullheight">
      <div className="hero-body">
        <div className="container">
          <h1 className="is-uppercase is-size-1">Thanks</h1>
          <hr />
          <p className="subtitle is-size-2">We will be in touch shortly!</p>
        </div>
      </div>
    </section>
  </div>
);

export default ThanksPage;
